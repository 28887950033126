<template>
  <SubNav
    class="subnav-position"
    :selected="'spaces'"
    :buildingId="buildingId"
  />
  <main>
    <form v-if="space" @submit.prevent="handleSave" class="edit-form">
      <div class="float-end">
        <router-link
          :to="{ name: 'Spaces', params: { buildingId: buildingId } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Space</h6>
      <div class="form-outline mb-4">
        <label>Name</label>
        <input type="text" required v-model="space.name" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Suite Number</label>
        <input
          type="text"
          required
          v-model="space.suiteNumber"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Potential Rent</label>
        <input type="text" v-model="space.potentialRent" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Availability</label>
        <input type="text" v-model="space.availability" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Usable Square Feet</label>
        <input type="number" v-model="space.sizeUsable" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Description</label>
        <textarea v-model="space.description" class="form-control long-text">
        </textarea>
      </div>
      <div class="form-outline mb-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="spaceAvailable"
            v-model="space.available"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Show as Available?
          </label>
        </div>
      </div>
      <div class="row">
        <!-- Floor Plan -->
        <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
          <div class="space-image">
            <img :src="space.floorPlanURLThumb" />
          </div>
          <div>
            <label>Upload Floor Plan (Min 200 x 200)</label>
          </div>
          <div class="error">{{ errorImageFP }}</div>
          <div v-if="isPendingFP">
            <button class="btn btn-secondary" disabled>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Saving...</span>
              </div>
            </button>
          </div>
          <div v-else>
            <input type="file" @change="handleImageFP" />
          </div>
        </div>
        <!-- Image 1 -->
        <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
          <div class="space-image">
            <img :src="space.image1URLThumb" />
          </div>
          <div>
            <label>Image 1 (Min 200 x 200)</label>
          </div>
          <div class="error">{{ errorImage1 }}</div>
          <div v-if="isPendingImage1">
            <button class="btn btn-secondary" disabled>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Saving...</span>
              </div>
            </button>
          </div>
          <div v-else>
            <input type="file" @change="handleImage1" />
          </div>
        </div>
        <!-- Image 2 -->
        <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
          <div class="space-image">
            <img :src="space.image2URLThumb" />
          </div>
          <div>
            <label>Image 2 (Min 200 x 200)</label>
          </div>
          <div class="error">{{ errorImage2 }}</div>
          <div v-if="isPendingImage2">
            <button class="btn btn-secondary" disabled>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Saving...</span>
              </div>
            </button>
          </div>
          <div v-else>
            <input type="file" @change="handleImage2" />
          </div>
        </div>
        <!-- Image 3 -->
        <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
          <div class="space-image">
            <img :src="space.image3URLThumb" />
          </div>
          <div>
            <label>Image 3 (Min 200 x 200)</label>
          </div>
          <div class="error">{{ errorImage3 }}</div>
          <div v-if="isPendingImage3">
            <button class="btn btn-secondary" disabled>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Saving...</span>
              </div>
            </button>
          </div>
          <div v-else>
            <input type="file" @change="handleImage3" />
          </div>
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Sort Order (ascending)</label>
        <input
          type="number"
          step="1"
          required
          v-model="space.order"
          class="form-control"
        />
      </div>
      <div class="error">{{ errorForm }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!errorForm" class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'Spaces', params: { buildingId: buildingId } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Space
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import { ref } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import getDocument from "@/composables/getDocument";
import { useRouter } from "vue-router";
import { timestamp } from "@/firebase/config";

export default {
  props: ["buildingId", "spaceId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const errorForm = ref(null);
    const router = useRouter();

    const { document: space } = getDocument("spaces", props.spaceId);

    const {
      error: errorImageFP,
      isImagePending: isPendingFP,
      url: fpURL,
      filePath: fpFilePath,
      urlThumb: fpURLThumb,
      filePathThumb: fpFilePathThumb,
      urlMedium: fpURLMedium,
      filePathMedium: fpFilePathMedium,
      uploadImageResize: uploadImageFPResize,
      deleteImage: deleteImageFP
    } = useStorage();

    const {
      error: errorImage1,
      isImagePending: isPendingImage1,
      url: image1URL,
      filePath: image1FilePath,
      urlThumb: image1URLThumb,
      filePathThumb: image1FilePathThumb,
      urlMedium: image1URLMedium,
      filePathMedium: image1FilePathMedium,
      uploadImageResize: uploadImage1Resize,
      deleteImage: deleteImage1
    } = useStorage();

    const {
      error: errorImage2,
      isImagePending: isPendingImage2,
      url: image2URL,
      filePath: image2FilePath,
      urlThumb: image2URLThumb,
      filePathThumb: image2FilePathThumb,
      urlMedium: image2URLMedium,
      filePathMedium: image2FilePathMedium,
      uploadImageResize: uploadImage2Resize,
      deleteImage: deleteImage2
    } = useStorage();

    const {
      error: errorImage3,
      isImagePending: isPendingImage3,
      url: image3URL,
      filePath: image3FilePath,
      urlThumb: image3URLThumb,
      filePathThumb: image3FilePathThumb,
      urlMedium: image3URLMedium,
      filePathMedium: image3FilePathMedium,
      uploadImageResize: uploadImage3Resize,
      deleteImage: deleteImage3
    } = useStorage();

    const {
      error: errorSpace,
      deleteDoc: deleteSpace,
      updateDoc: updateSpace
    } = useDocument("spaces", props.spaceId);

    const saveDetails = async () => {
      await updateSpace({
        name: space.value.name,
        suiteNumber: space.value.suiteNumber,
        available: space.value.available,
        order: space.value.order,
        potentialRent: space.value.potentialRent
          ? space.value.potentialRent
          : "",
        availability: space.value.availability ? space.value.availability : "",
        sizeUsable: space.value.sizeUsable ? space.value.sizeUsable : "",
        description: space.value.description ? space.value.description : "",
        floorPlanFilePath: space.value.floorPlanFilePath
          ? space.value.floorPlanFilePath
          : "",
        floorPlanURL: space.value.floorPlanURL ? space.value.floorPlanURL : "",
        floorPlanFilePathThumb: space.value.floorPlanFilePathThumb
          ? space.value.floorPlanFilePathThumb
          : "",
        floorPlanURLThumb: space.value.floorPlanURLThumb
          ? space.value.floorPlanURLThumb
          : "",
        floorPlanFilePathMedium: space.value.floorPlanFilePathMedium
          ? space.value.floorPlanFilePathMedium
          : "",
        floorPlanURLMedium: space.value.floorPlanURLMedium
          ? space.value.floorPlanURLMedium
          : "",
        image1FilePath: space.value.image1FilePath
          ? space.value.image1FilePath
          : "",
        image1URL: space.value.image1URL ? space.value.image1URL : "",
        image1FilePathThumb: space.value.image1FilePathThumb
          ? space.value.image1FilePathThumb
          : "",
        image1URLThumb: space.value.image1URLThumb
          ? space.value.image1URLThumb
          : "",
        image1FilePathMedium: space.value.image1FilePathMedium
          ? space.value.image1FilePathMedium
          : "",
        image1URLMedium: space.value.image1URLMedium
          ? space.value.image1URLMedium
          : "",
        image2FilePath: space.value.image2FilePath
          ? space.value.image2FilePath
          : "",
        image2URL: space.value.image2URL ? space.value.image2URL : "",
        image2FilePathThumb: space.value.image2FilePathThumb
          ? space.value.image2FilePathThumb
          : "",
        image2URLThumb: space.value.image2URLThumb
          ? space.value.image2URLThumb
          : "",
        image2FilePathMedium: space.value.image2FilePathMedium
          ? space.value.image2FilePathMedium
          : "",
        image2URLMedium: space.value.image2URLMedium
          ? space.value.image2URLMedium
          : "",
        image3FilePath: space.value.image3FilePath
          ? space.value.image3FilePath
          : "",
        image3URL: space.value.image3URL ? space.value.image3URL : "",
        image3FilePathThumb: space.value.image3FilePathThumb
          ? space.value.image3FilePathThumb
          : "",
        image3URLThumb: space.value.image3URLThumb
          ? space.value.image3URLThumb
          : "",
        image3FilePathMedium: space.value.image3FilePathMedium
          ? space.value.image3FilePathMedium
          : "",
        image3URLMedium: space.value.image3URLMedium
          ? space.value.image3URLMedium
          : "",
        editedAt: timestamp()
      });
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        errorForm.value = null;
        await saveDetails();
        isPending.value = false;
        if (errorSpace.value) {
          errorForm.value = errorSpace.value;
        } else {
          router.push({
            name: "Spaces",
            params: { buildingId: props.buildingId }
          });
        }
      } catch (err) {
        isPending.value = false;
        errorForm.value = `System Error: ${err.message}`;
      }
    };

    const deleteFloorPlanImages = async () => {
      if (space.value.floorPlanFilePath) {
        await deleteImageFP(space.value.floorPlanFilePath);
        space.value.floorPlanFilePath = "";
        space.value.floorPlanURL = "";
      }
      if (space.value.floorPlanFilePathThumb) {
        await deleteImageFP(space.value.floorPlanFilePathThumb);
        space.value.floorPlanFilePathThumb = "";
        space.value.floorPlanURLThumb = "";
      }
      if (space.value.floorPlanFilePathMedium) {
        await deleteImageFP(space.value.floorPlanFilePathMedium);
        space.value.floorPlanFilePathMedium = "";
        space.value.floorPlanURLMedium = "";
      }
    };

    const deleteImage1Images = async () => {
      if (space.value.image1FilePath) {
        await deleteImage1(space.value.image1FilePath);
        space.value.image1FilePath = "";
        space.value.image1URL = "";
      }
      if (space.value.image1FilePathThumb) {
        await deleteImage1(space.value.image1FilePathThumb);
        space.value.image1FilePathThumb = "";
        space.value.image1URLThumb = "";
      }
      if (space.value.image1FilePathMedium) {
        await deleteImage1(space.value.image1FilePathMedium);
        space.value.image1FilePathMedium = "";
        space.value.image1URLMedium = "";
      }
    };

    const deleteImage2Images = async () => {
      if (space.value.image2FilePath) {
        await deleteImage2(space.value.image2FilePath);
        space.value.image2FilePath = "";
        space.value.image2URL = "";
      }
      if (space.value.image2FilePathThumb) {
        await deleteImage2(space.value.image2FilePathThumb);
        space.value.image2FilePathThumb = "";
        space.value.image2URLThumb = "";
      }
      if (space.value.image2FilePathMedium) {
        await deleteImage2(space.value.image2FilePathMedium);
        space.value.image2FilePathMedium = "";
        space.value.image2URLMedium = "";
      }
    };

    const deleteImage3Images = async () => {
      if (space.value.image3FilePath) {
        await deleteImage3(space.value.image3FilePath);
        space.value.image3FilePath = "";
        space.value.image3URL = "";
      }
      if (space.value.image3FilePathThumb) {
        await deleteImage3(space.value.image3FilePathThumb);
        space.value.image3FilePathThumb = "";
        space.value.image3URLThumb = "";
      }
      if (space.value.image3FilePathMedium) {
        await deleteImage3(space.value.image3FilePathMedium);
        space.value.image3FilePathMedium = "";
        space.value.image3URLMedium = "";
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      errorForm.value = null;
      await deleteFloorPlanImages();
      await deleteImage1Images();
      await deleteImage2Images();
      await deleteImage3Images();

      await deleteSpace();
      isPending.value = false;
      if (errorSpace.value) {
        errorForm.value = errorSpace.value;
      } else {
        router.push({
          name: "Spaces",
          params: { buildingId: props.buildingId }
        });
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleImageFP = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorSpace.value = null;
        //Delete the existing images so our resize doesn't break
        await deleteFloorPlanImages();
        await uploadImageFPResize(
          `spaces/${props.buildingId}/${props.spaceId}`,
          "floorPlan",
          selected
        );
        if (!errorImageFP.value) {
          space.value.floorPlanFilePath = fpFilePath.value;
          space.value.floorPlanURL = fpURL.value;
          space.value.floorPlanFilePathThumb = fpFilePathThumb.value;
          space.value.floorPlanURLThumb = fpURLThumb.value;
          space.value.floorPlanFilePathMedium = fpFilePathMedium.value;
          space.value.floorPlanURLMedium = fpURLMedium.value;

          await saveDetails();
          if (errorSpace.value) {
            errorForm.value = errorSpace.value;
          }
        }
      } else {
        errorImageFP.value = "Please select an image file (png or jpg/jpeg)";
      }
    };

    const handleImage1 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorSpace.value = null;
        //Delete the existing images so our resize doesn't break
        await deleteImage1Images();
        await uploadImage1Resize(
          `spaces/${props.buildingId}/${props.spaceId}`,
          "image1",
          selected
        );
        if (!errorImage1.value) {
          space.value.image1FilePath = image1FilePath.value;
          space.value.image1URL = image1URL.value;
          space.value.image1FilePathThumb = image1FilePathThumb.value;
          space.value.image1URLThumb = image1URLThumb.value;
          space.value.image1FilePathMedium = image1FilePathMedium.value;
          space.value.image1URLMedium = image1URLMedium.value;

          await saveDetails();
          if (errorSpace.value) {
            errorForm.value = errorSpace.value;
          }
        }
      } else {
        errorImage1.value = "Please select an image file (png or jpg/jpeg)";
      }
    };

    const handleImage2 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorSpace.value = null;
        //Delete the existing images so our resize doesn't break
        await deleteImage2Images();
        await uploadImage2Resize(
          `spaces/${props.buildingId}/${props.spaceId}`,
          "image2",
          selected
        );
        if (!errorImage2.value) {
          space.value.image2FilePath = image2FilePath.value;
          space.value.image2URL = image2URL.value;
          space.value.image2FilePathThumb = image2FilePathThumb.value;
          space.value.image2URLThumb = image2URLThumb.value;
          space.value.image2FilePathMedium = image2FilePathMedium.value;
          space.value.image2URLMedium = image2URLMedium.value;

          await saveDetails();
          if (errorSpace.value) {
            errorForm.value = errorSpace.value;
          }
        }
      } else {
        errorImage2.value = "Please select an image file (png or jpg/jpeg)";
      }
    };

    const handleImage3 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorSpace.value = null;
        //Delete the existing images so our resize doesn't break
        await deleteImage3Images();
        await uploadImage3Resize(
          `spaces/${props.buildingId}/${props.spaceId}`,
          "image3",
          selected
        );
        if (!errorImage3.value) {
          space.value.image3FilePath = image3FilePath.value;
          space.value.image3URL = image3URL.value;
          space.value.image3FilePathThumb = image3FilePathThumb.value;
          space.value.image3URLThumb = image3URLThumb.value;
          space.value.image3FilePathMedium = image3FilePathMedium.value;
          space.value.image3URLMedium = image3URLMedium.value;

          await saveDetails();
          if (errorSpace.value) {
            errorForm.value = errorSpace.value;
          }
        }
      } else {
        errorImage3.value = "Please select an image file (png or jpg/jpeg)";
      }
    };

    return {
      errorImageFP,
      errorImage1,
      errorImage2,
      errorImage3,
      isPending,
      isPendingFP,
      isPendingImage1,
      isPendingImage2,
      isPendingImage3,
      errorForm,
      handleSave,
      handleDelete,
      handleImageFP,
      handleImage1,
      handleImage2,
      handleImage3,
      space
    };
  }
};
</script>

<style>
</style>